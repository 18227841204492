import React from "react";
import { Nunito } from "next/font/google";
import Head from "next/head";
import Script from "next/script";
import { Provider } from "urql";
import {
  AuthProvider,
  StorageProvider,
  useAuth,
  useGraphQLClient,
} from "@drumbeat/hooks";
import "../styles/globals.css";
import { DEFAULT_AUTH_CONFIG } from "../constants";
import { AppPropsWithLayout } from "../types";

const nunito = Nunito({
  subsets: ["latin"],
  style: ["normal", "italic"],
  weight: ["400", "500", "700", "900"],
  display: "swap",
  variable: "--font-nunito",
});

const GraphQLProvider = ({ children }) => {
  const auth = useAuth();
  const graphqlClient = useGraphQLClient({ auth });
  if (auth.isLoading) return <></>;
  // @ts-ignore
  return <Provider value={graphqlClient}>{children}</Provider>;
};

const localStorageProvider: StorageProvider =
  typeof window !== "undefined" && localStorage
    ? {
        get: (key) => localStorage.getItem(key),
        set: (key, value) => localStorage.setItem(key, value),
        remove: (key) => localStorage.removeItem(key),
      }
    : {
        get: (_key) => null,
        set: (_key, _value) => {},
        remove: (_key) => {},
      };

export default function App({ Component, pageProps }: AppPropsWithLayout) {
  const getLayout = Component.getLayout ?? ((page) => page);
  const authConfig = { ...DEFAULT_AUTH_CONFIG, localStorageProvider };
  React.useEffect(() => {
    if (typeof window !== "undefined") {
      const queryParameters = new URLSearchParams(window.location.search);
      const gclid = queryParameters.get("gclid");
      if (gclid) window.localStorage.setItem("gclid", gclid);
      const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      if (timezone) window.localStorage.setItem("timezone", timezone);
    }
  }, []);

  return (
    <>
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <Script
        strategy="lazyOnload"
        src="https://www.googletagmanager.com/gtag/js?id=G-3C1RYV2R17"
      />
      <Script id="google-analytics" strategy="lazyOnload">
        {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-3C1RYV2R17');
        `}
      </Script>
      <Script id="google-tag-manager" strategy="lazyOnload">
        {`
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','GTM-546G62ZH');
        `}
      </Script>

      <iframe
        src="https://www.googletagmanager.com/ns.html?id=GTM-546G62ZH"
        height="0"
        width="0"
        style={{ display: "none", visibility: "hidden" }}
      ></iframe>
      <AuthProvider config={authConfig}>
        <Head>
          <title>Anonymous Health: Overcome Habits</title>
          <link
            rel="icon"
            type="image/png"
            sizes="128x128"
            href="/128_favicon.png"
          />
        </Head>
        <GraphQLProvider>
          <main className={nunito.className}>
            {getLayout(<Component {...pageProps} />)}
          </main>
        </GraphQLProvider>
      </AuthProvider>
    </>
  );
}
