"use strict";
var __create = Object.create;
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __getOwnPropNames = Object.getOwnPropertyNames;
var __getProtoOf = Object.getPrototypeOf;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __export = (target, all) => {
  for (var name2 in all)
    __defProp(target, name2, { get: all[name2], enumerable: true });
};
var __copyProps = (to, from, except, desc) => {
  if (from && typeof from === "object" || typeof from === "function") {
    for (let key of __getOwnPropNames(from))
      if (!__hasOwnProp.call(to, key) && key !== except)
        __defProp(to, key, { get: () => from[key], enumerable: !(desc = __getOwnPropDesc(from, key)) || desc.enumerable });
  }
  return to;
};
var __toESM = (mod, isNodeMode, target) => (target = mod != null ? __create(__getProtoOf(mod)) : {}, __copyProps(
  // If the importer is in node compatibility mode or this is not an ESM
  // file that has been converted to a CommonJS file using a Babel-
  // compatible transform (i.e. "__esModule" has not been set), then set
  // "default" to the CommonJS "module.exports" for node compatibility.
  isNodeMode || !mod || !mod.__esModule ? __defProp(target, "default", { value: mod, enumerable: true }) : target,
  mod
));
var __toCommonJS = (mod) => __copyProps(__defProp({}, "__esModule", { value: true }), mod);

// src/index.tsx
var src_exports = {};
__export(src_exports, {
  AuthContext: () => AuthContext,
  AuthProvider: () => AuthProvider,
  configureAuth: () => configureAuth,
  currentSession: () => currentSession,
  useActivityDetection: () => useActivityDetection,
  useAltGraphQLClient: () => useAltGraphQLClient,
  useAuth: () => useAuth,
  useBackgroundColor: () => useBackgroundColor,
  useGraphQLClient: () => useGraphQLClient,
  useInput: () => useInput,
  useViewer: () => useViewer
});
module.exports = __toCommonJS(src_exports);

// src/auth/index.tsx
var import_react = __toESM(require("react"));
var import_aws_amplify = require("aws-amplify");
var import_auth = require("aws-amplify/auth");
var import_utils = require("aws-amplify/utils");

// src/utils.ts
var import_schema = require("@drumbeat/schema");
var generatePassword = (length) => {
  const specialCharacters = "!@#$%^&*()_+~`|}{[]:;?><,./-=";
  const numbers = "0123456789";
  const letters = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
  const allCharacters = specialCharacters + numbers + letters;
  let password = "";
  for (let i3 = 0; i3 < length; i3++) {
    const character = Math.floor(Math.random() * allCharacters.length);
    password += allCharacters.substring(character, character + 1);
  }
  const specialChar1 = Math.floor(Math.random() * specialCharacters.length);
  const specialChar2 = Math.floor(Math.random() * specialCharacters.length);
  const number1 = Math.floor(Math.random() * numbers.length);
  const number2 = Math.floor(Math.random() * numbers.length);
  password += specialCharacters.substring(specialChar1, specialChar1 + 1) + specialCharacters.substring(specialChar2, specialChar2 + 1) + numbers.substring(number1, number1 + 1) + numbers.substring(number2, number2 + 1);
  return password;
};
var USER_TYPE_COGNITO_CLIENT_ID_MAP = {
  [import_schema.UserType.Therapist]: process.env.NEXT_PUBLIC_THERAPIST_USER_POOL_WEB_CLIENT_ID,
  [import_schema.UserType.Admin]: process.env.NEXT_PUBLIC_ADMIN_USER_POOL_WEB_CLIENT_ID,
  [import_schema.UserType.Client]: process.env.NEXT_PUBLIC_CLIENT_USER_POOL_WEB_CLIENT_ID
};
var USER_TYPES = Object.keys(USER_TYPE_COGNITO_CLIENT_ID_MAP);

// src/auth/index.tsx
var ACCESS_TOKEN_KEY = "accessToken";
var currentAmplifyAuthConfig = null;
var storage;
var configureAuth = (config) => {
  if (currentAmplifyAuthConfig)
    return;
  storage = config.localStorageProvider;
  const authConfig = {
    Cognito: {
      userPoolId: config.userPoolId,
      userPoolClientId: config.userPoolWebClientId,
      loginWith: {
        email: true,
        oauth: {
          domain: config.cognitoDomain,
          scopes: ["openid"],
          redirectSignIn: [config.redirectSignIn],
          redirectSignOut: [config.redirectSignOut],
          responseType: "code",
          // or 'token', note that REFRESH token will only be generated when the responseType is code
          providers: ["Google"]
        }
      }
    }
  };
  const amplifyConfig = {
    Auth: { ...authConfig }
  };
  import_aws_amplify.Amplify.configure(amplifyConfig);
  currentAmplifyAuthConfig = amplifyConfig;
};
var AuthContext = import_react.default.createContext({
  viewer: null,
  setViewer: (viewer) => {
  }
});
var AuthProvider = ({ children, config }) => {
  const [viewer, setViewer] = import_react.default.useState(
    null
  );
  configureAuth({ ...config });
  const { onSignedOutFunc } = config;
  import_react.default.useEffect(() => {
    const unsubscribe = import_utils.Hub.listen("auth", async ({ payload }) => {
      const { accessToken } = (await (0, import_auth.fetchAuthSession)()).tokens ?? {};
      if (accessToken) {
        storage.set(ACCESS_TOKEN_KEY, accessToken.toString());
      }
      switch (payload.event) {
        case "signedIn":
          break;
        case "signedOut":
          if (onSignedOutFunc) {
            await onSignedOutFunc();
          }
          break;
        case "tokenRefresh":
          break;
        case "tokenRefresh_failure":
          break;
        case "signInWithRedirect":
          break;
        case "signInWithRedirect_failure":
          break;
        case "customOAuthState":
          break;
      }
    });
    return unsubscribe;
  }, [config]);
  return /* @__PURE__ */ import_react.default.createElement(AuthContext.Provider, { value: { viewer, setViewer } }, children);
};
function useViewer() {
  const { viewer, setViewer } = import_react.default.useContext(AuthContext);
  return { viewer, setViewer };
}
async function currentSession() {
  try {
    const { accessToken, idToken } = (await (0, import_auth.fetchAuthSession)({ forceRefresh: true })).tokens ?? {};
    return { accessToken, idToken };
  } catch (err) {
    console.log(err);
    throw err;
  }
}
var useAuth = () => {
  const [userObj, setUserObj] = import_react.default.useState({
    user: null,
    isLoading: true
  });
  const [customState, setCustomState] = import_react.default.useState(
    void 0
  );
  const updateUser = async (user) => {
    setUserObj({ user, isLoading: false });
    if (user === null) {
      storage.remove(ACCESS_TOKEN_KEY);
    } else {
      const { accessToken } = await currentSession();
      if (accessToken) {
        storage.set(ACCESS_TOKEN_KEY, accessToken.toString());
      }
    }
  };
  import_react.default.useEffect(() => {
    (async () => {
      try {
        const user = await (0, import_auth.getCurrentUser)();
        await updateUser(user);
      } catch (error2) {
        await updateUser(null);
      }
    })();
  }, []);
  const signIn = import_react.default.useCallback(
    async ({ email, password, clientMetadata }) => {
      const signInResponse = await (0, import_auth.signIn)({
        username: email,
        options: { authFlowType: "CUSTOM_WITHOUT_SRP", clientMetadata }
      });
      if (signInResponse.isSignedIn) {
        const user = await (0, import_auth.getCurrentUser)();
        await updateUser(user);
        return user;
      }
      if (!signInResponse.nextStep) {
        throw new Error("Unable to sign in");
      }
    },
    [setUserObj]
  );
  const signOut = import_react.default.useCallback(async () => {
    await (0, import_auth.signOut)();
    await updateUser(null);
    storage.remove(ACCESS_TOKEN_KEY);
  }, [setUserObj]);
  const signInWithGoogle = import_react.default.useCallback(async () => {
    await (0, import_auth.signInWithRedirect)({
      provider: "Google"
    });
  }, []);
  const signUpWithEmail = import_react.default.useCallback(
    async (email) => {
      const cognitoUser = await (0, import_auth.signUp)({
        username: email,
        password: generatePassword(10),
        options: {
          userAttributes: {
            email
          }
        }
      });
      return cognitoUser;
    },
    [setUserObj]
  );
  const refreshAllTokens = import_react.default.useCallback(async () => {
    try {
      const { accessToken, idToken } = await currentSession();
      if (!accessToken || !idToken) {
        storage.remove(ACCESS_TOKEN_KEY);
      } else {
        storage.set(ACCESS_TOKEN_KEY, accessToken.toString());
      }
    } catch (e3) {
      storage.remove(ACCESS_TOKEN_KEY);
      console.error("Unable to refresh all tokens", e3);
      throw e3;
    }
  }, []);
  const refreshAccessToken = import_react.default.useCallback(async () => {
    try {
      const user = await (0, import_auth.getCurrentUser)();
      await updateUser(user);
    } catch (e3) {
      updateUser(null);
      console.error("Unable to refresh Token", e3);
      throw e3;
    }
  }, []);
  const signInWithEmail = import_react.default.useCallback(async (email) => {
    await signIn({ email });
  }, []);
  const authenticate = import_react.default.useCallback(
    async (magicLink, email) => {
      const fixedMagicLink = magicLink ? magicLink : null;
      const fixedEmail = email ? email.replaceAll(" ", "+") : null;
      if (fixedEmail && fixedMagicLink) {
        const signInResponse = await (0, import_auth.signIn)({
          username: fixedEmail,
          options: { authFlowType: "CUSTOM_WITHOUT_SRP" }
        });
        if (signInResponse.nextStep.signInStep === "CONFIRM_SIGN_IN_WITH_CUSTOM_CHALLENGE") {
          await (0, import_auth.confirmSignIn)({ challengeResponse: fixedMagicLink });
        }
      }
      await refreshAccessToken();
    },
    []
  );
  return {
    isLoggedIn: !!userObj.user,
    isLoading: userObj.isLoading,
    accessToken: storage.get(ACCESS_TOKEN_KEY),
    getAccessToken: () => storage.get(ACCESS_TOKEN_KEY),
    signInWithGoogle,
    signInWithEmail,
    signUpWithEmail,
    signOut,
    refreshAccessToken,
    refreshAllTokens,
    authenticate
  };
};

// src/useBackgroundColor.tsx
var import_react2 = require("react");
var useBackgroundColor = (color) => {
  (0, import_react2.useEffect)(() => {
    const handleRouteChange = () => {
      document.documentElement.style.backgroundColor = color;
      document.body.style.backgroundColor = color;
    };
    handleRouteChange();
    return () => {
      document.documentElement.style.backgroundColor = "";
      document.body.style.backgroundColor = "";
    };
  }, [color]);
};

// src/altGraphqlClient.ts
var import_react3 = require("react");

// ../../node_modules/@0no-co/graphql.web/dist/graphql.web.mjs
var e = {
  NAME: "Name",
  DOCUMENT: "Document",
  OPERATION_DEFINITION: "OperationDefinition",
  VARIABLE_DEFINITION: "VariableDefinition",
  SELECTION_SET: "SelectionSet",
  FIELD: "Field",
  ARGUMENT: "Argument",
  FRAGMENT_SPREAD: "FragmentSpread",
  INLINE_FRAGMENT: "InlineFragment",
  FRAGMENT_DEFINITION: "FragmentDefinition",
  VARIABLE: "Variable",
  INT: "IntValue",
  FLOAT: "FloatValue",
  STRING: "StringValue",
  BOOLEAN: "BooleanValue",
  NULL: "NullValue",
  ENUM: "EnumValue",
  LIST: "ListValue",
  OBJECT: "ObjectValue",
  OBJECT_FIELD: "ObjectField",
  DIRECTIVE: "Directive",
  NAMED_TYPE: "NamedType",
  LIST_TYPE: "ListType",
  NON_NULL_TYPE: "NonNullType"
};
var GraphQLError = class extends Error {
  constructor(e3, r2, i3, n2, a2, t2, o2) {
    super(e3);
    this.name = "GraphQLError";
    this.message = e3;
    if (a2) {
      this.path = a2;
    }
    if (r2) {
      this.nodes = Array.isArray(r2) ? r2 : [r2];
    }
    if (i3) {
      this.source = i3;
    }
    if (n2) {
      this.positions = n2;
    }
    if (t2) {
      this.originalError = t2;
    }
    var l3 = o2;
    if (!l3 && t2) {
      var u3 = t2.extensions;
      if (u3 && "object" == typeof u3) {
        l3 = u3;
      }
    }
    this.extensions = l3 || {};
  }
  toJSON() {
    return {
      ...this,
      message: this.message
    };
  }
  toString() {
    return this.message;
  }
  get [Symbol.toStringTag]() {
    return "GraphQLError";
  }
};
var i;
var n;
function error(e3) {
  return new GraphQLError(`Syntax Error: Unexpected token at ${n} in ${e3}`);
}
function advance(e3) {
  e3.lastIndex = n;
  if (e3.test(i)) {
    return i.slice(n, n = e3.lastIndex);
  }
}
var a = / +(?=[^\s])/y;
function blockString(e3) {
  var r2 = e3.split("\n");
  var i3 = "";
  var n2 = 0;
  var t2 = 0;
  var o2 = r2.length - 1;
  for (var l3 = 0; l3 < r2.length; l3++) {
    a.lastIndex = 0;
    if (a.test(r2[l3])) {
      if (l3 && (!n2 || a.lastIndex < n2)) {
        n2 = a.lastIndex;
      }
      t2 = t2 || l3;
      o2 = l3;
    }
  }
  for (var u3 = t2; u3 <= o2; u3++) {
    if (u3 !== t2) {
      i3 += "\n";
    }
    i3 += r2[u3].slice(n2).replace(/\\"""/g, '"""');
  }
  return i3;
}
function ignored() {
  for (var e3 = 0 | i.charCodeAt(n++); 9 === e3 || 10 === e3 || 13 === e3 || 32 === e3 || 35 === e3 || 44 === e3 || 65279 === e3; e3 = 0 | i.charCodeAt(n++)) {
    if (35 === e3) {
      while (10 !== (e3 = i.charCodeAt(n++)) && 13 !== e3) {
      }
    }
  }
  n--;
}
var t = /[_A-Za-z]\w*/y;
function name() {
  var e3;
  if (e3 = advance(t)) {
    return {
      kind: "Name",
      value: e3
    };
  }
}
var o = /(?:null|true|false)/y;
var l = /\$[_A-Za-z]\w*/y;
var u = /-?\d+/y;
var v = /(?:\.\d+)?[eE][+-]?\d+|\.\d+/y;
var d = /\\/g;
var s = /"""(?:"""|(?:[\s\S]*?[^\\])""")/y;
var c = /"(?:"|[^\r\n]*?[^\\]")/y;
function value(e3) {
  var r2;
  var a2;
  if (a2 = advance(o)) {
    r2 = "null" === a2 ? {
      kind: "NullValue"
    } : {
      kind: "BooleanValue",
      value: "true" === a2
    };
  } else if (!e3 && (a2 = advance(l))) {
    r2 = {
      kind: "Variable",
      name: {
        kind: "Name",
        value: a2.slice(1)
      }
    };
  } else if (a2 = advance(u)) {
    var f3 = a2;
    if (a2 = advance(v)) {
      r2 = {
        kind: "FloatValue",
        value: f3 + a2
      };
    } else {
      r2 = {
        kind: "IntValue",
        value: f3
      };
    }
  } else if (a2 = advance(t)) {
    r2 = {
      kind: "EnumValue",
      value: a2
    };
  } else if (a2 = advance(s)) {
    r2 = {
      kind: "StringValue",
      value: blockString(a2.slice(3, -3)),
      block: true
    };
  } else if (a2 = advance(c)) {
    r2 = {
      kind: "StringValue",
      value: d.test(a2) ? JSON.parse(a2) : a2.slice(1, -1),
      block: false
    };
  } else if (r2 = function list(e4) {
    var r3;
    if (91 === i.charCodeAt(n)) {
      n++;
      ignored();
      var a3 = [];
      while (r3 = value(e4)) {
        a3.push(r3);
      }
      if (93 !== i.charCodeAt(n++)) {
        throw error("ListValue");
      }
      ignored();
      return {
        kind: "ListValue",
        values: a3
      };
    }
  }(e3) || function object(e4) {
    if (123 === i.charCodeAt(n)) {
      n++;
      ignored();
      var r3 = [];
      var a3;
      while (a3 = name()) {
        ignored();
        if (58 !== i.charCodeAt(n++)) {
          throw error("ObjectField");
        }
        ignored();
        var t2 = value(e4);
        if (!t2) {
          throw error("ObjectField");
        }
        r3.push({
          kind: "ObjectField",
          name: a3,
          value: t2
        });
      }
      if (125 !== i.charCodeAt(n++)) {
        throw error("ObjectValue");
      }
      ignored();
      return {
        kind: "ObjectValue",
        fields: r3
      };
    }
  }(e3)) {
    return r2;
  }
  ignored();
  return r2;
}
function arguments_(e3) {
  var r2 = [];
  ignored();
  if (40 === i.charCodeAt(n)) {
    n++;
    ignored();
    var a2;
    while (a2 = name()) {
      ignored();
      if (58 !== i.charCodeAt(n++)) {
        throw error("Argument");
      }
      ignored();
      var t2 = value(e3);
      if (!t2) {
        throw error("Argument");
      }
      r2.push({
        kind: "Argument",
        name: a2,
        value: t2
      });
    }
    if (!r2.length || 41 !== i.charCodeAt(n++)) {
      throw error("Argument");
    }
    ignored();
  }
  return r2;
}
function directives(e3) {
  var r2 = [];
  ignored();
  while (64 === i.charCodeAt(n)) {
    n++;
    var a2 = name();
    if (!a2) {
      throw error("Directive");
    }
    ignored();
    r2.push({
      kind: "Directive",
      name: a2,
      arguments: arguments_(e3)
    });
  }
  return r2;
}
function field() {
  var e3 = name();
  if (e3) {
    ignored();
    var r2;
    if (58 === i.charCodeAt(n)) {
      n++;
      ignored();
      r2 = e3;
      if (!(e3 = name())) {
        throw error("Field");
      }
      ignored();
    }
    return {
      kind: "Field",
      alias: r2,
      name: e3,
      arguments: arguments_(false),
      directives: directives(false),
      selectionSet: selectionSet()
    };
  }
}
function type() {
  var e3;
  ignored();
  if (91 === i.charCodeAt(n)) {
    n++;
    ignored();
    var r2 = type();
    if (!r2 || 93 !== i.charCodeAt(n++)) {
      throw error("ListType");
    }
    e3 = {
      kind: "ListType",
      type: r2
    };
  } else if (e3 = name()) {
    e3 = {
      kind: "NamedType",
      name: e3
    };
  } else {
    throw error("NamedType");
  }
  ignored();
  if (33 === i.charCodeAt(n)) {
    n++;
    ignored();
    return {
      kind: "NonNullType",
      type: e3
    };
  } else {
    return e3;
  }
}
var f = /on/y;
function typeCondition() {
  if (advance(f)) {
    ignored();
    var e3 = name();
    if (!e3) {
      throw error("NamedType");
    }
    ignored();
    return {
      kind: "NamedType",
      name: e3
    };
  }
}
var p = /\.\.\./y;
function fragmentSpread() {
  if (advance(p)) {
    ignored();
    var e3 = n;
    var r2;
    if ((r2 = name()) && "on" !== r2.value) {
      return {
        kind: "FragmentSpread",
        name: r2,
        directives: directives(false)
      };
    } else {
      n = e3;
      var i3 = typeCondition();
      var a2 = directives(false);
      var t2 = selectionSet();
      if (!t2) {
        throw error("InlineFragment");
      }
      return {
        kind: "InlineFragment",
        typeCondition: i3,
        directives: a2,
        selectionSet: t2
      };
    }
  }
}
function selectionSet() {
  var e3;
  ignored();
  if (123 === i.charCodeAt(n)) {
    n++;
    ignored();
    var r2 = [];
    while (e3 = fragmentSpread() || field()) {
      r2.push(e3);
    }
    if (!r2.length || 125 !== i.charCodeAt(n++)) {
      throw error("SelectionSet");
    }
    ignored();
    return {
      kind: "SelectionSet",
      selections: r2
    };
  }
}
var m = /fragment/y;
function fragmentDefinition() {
  if (advance(m)) {
    ignored();
    var e3 = name();
    if (!e3) {
      throw error("FragmentDefinition");
    }
    ignored();
    var r2 = typeCondition();
    if (!r2) {
      throw error("FragmentDefinition");
    }
    var i3 = directives(false);
    var n2 = selectionSet();
    if (!n2) {
      throw error("FragmentDefinition");
    }
    return {
      kind: "FragmentDefinition",
      name: e3,
      typeCondition: r2,
      directives: i3,
      selectionSet: n2
    };
  }
}
var g = /(?:query|mutation|subscription)/y;
function operationDefinition() {
  var e3;
  var r2;
  var a2 = [];
  var t2 = [];
  if (e3 = advance(g)) {
    ignored();
    r2 = name();
    a2 = function variableDefinitions() {
      var e4;
      var r3 = [];
      ignored();
      if (40 === i.charCodeAt(n)) {
        n++;
        ignored();
        while (e4 = advance(l)) {
          ignored();
          if (58 !== i.charCodeAt(n++)) {
            throw error("VariableDefinition");
          }
          var a3 = type();
          var t3 = void 0;
          if (61 === i.charCodeAt(n)) {
            n++;
            ignored();
            if (!(t3 = value(true))) {
              throw error("VariableDefinition");
            }
          }
          ignored();
          r3.push({
            kind: "VariableDefinition",
            variable: {
              kind: "Variable",
              name: {
                kind: "Name",
                value: e4.slice(1)
              }
            },
            type: a3,
            defaultValue: t3,
            directives: directives(true)
          });
        }
        if (41 !== i.charCodeAt(n++)) {
          throw error("VariableDefinition");
        }
        ignored();
      }
      return r3;
    }();
    t2 = directives(false);
  }
  var o2 = selectionSet();
  if (o2) {
    return {
      kind: "OperationDefinition",
      operation: e3 || "query",
      name: r2,
      variableDefinitions: a2,
      directives: t2,
      selectionSet: o2
    };
  }
}
function parse(e3, r2) {
  i = "string" == typeof e3.body ? e3.body : e3;
  n = 0;
  return function document2() {
    var e4;
    ignored();
    var r3 = [];
    while (e4 = fragmentDefinition() || operationDefinition()) {
      r3.push(e4);
    }
    return {
      kind: "Document",
      definitions: r3
    };
  }();
}
function printString(e3) {
  return JSON.stringify(e3);
}
function printBlockString(e3) {
  return '"""\n' + e3.replace(/"""/g, '\\"""') + '\n"""';
}
var hasItems = (e3) => !(!e3 || !e3.length);
var y = {
  OperationDefinition(e3) {
    if ("query" === e3.operation && !e3.name && !hasItems(e3.variableDefinitions) && !hasItems(e3.directives)) {
      return y.SelectionSet(e3.selectionSet);
    }
    var r2 = e3.operation;
    if (e3.name) {
      r2 += " " + e3.name.value;
    }
    if (hasItems(e3.variableDefinitions)) {
      if (!e3.name) {
        r2 += " ";
      }
      r2 += "(" + e3.variableDefinitions.map(y.VariableDefinition).join(", ") + ")";
    }
    if (hasItems(e3.directives)) {
      r2 += " " + e3.directives.map(y.Directive).join(" ");
    }
    return r2 + " " + y.SelectionSet(e3.selectionSet);
  },
  VariableDefinition(e3) {
    var r2 = y.Variable(e3.variable) + ": " + print(e3.type);
    if (e3.defaultValue) {
      r2 += " = " + print(e3.defaultValue);
    }
    if (hasItems(e3.directives)) {
      r2 += " " + e3.directives.map(y.Directive).join(" ");
    }
    return r2;
  },
  Field(e3) {
    var r2 = (e3.alias ? e3.alias.value + ": " : "") + e3.name.value;
    if (hasItems(e3.arguments)) {
      var i3 = e3.arguments.map(y.Argument);
      var n2 = r2 + "(" + i3.join(", ") + ")";
      r2 = n2.length > 80 ? r2 + "(\n  " + i3.join("\n").replace(/\n/g, "\n  ") + "\n)" : n2;
    }
    if (hasItems(e3.directives)) {
      r2 += " " + e3.directives.map(y.Directive).join(" ");
    }
    return e3.selectionSet ? r2 + " " + y.SelectionSet(e3.selectionSet) : r2;
  },
  StringValue: (e3) => e3.block ? printBlockString(e3.value) : printString(e3.value),
  BooleanValue: (e3) => "" + e3.value,
  NullValue: (e3) => "null",
  IntValue: (e3) => e3.value,
  FloatValue: (e3) => e3.value,
  EnumValue: (e3) => e3.value,
  Name: (e3) => e3.value,
  Variable: (e3) => "$" + e3.name.value,
  ListValue: (e3) => "[" + e3.values.map(print).join(", ") + "]",
  ObjectValue: (e3) => "{" + e3.fields.map(y.ObjectField).join(", ") + "}",
  ObjectField: (e3) => e3.name.value + ": " + print(e3.value),
  Document: (e3) => hasItems(e3.definitions) ? e3.definitions.map(print).join("\n\n") : "",
  SelectionSet: (e3) => "{\n  " + e3.selections.map(print).join("\n").replace(/\n/g, "\n  ") + "\n}",
  Argument: (e3) => e3.name.value + ": " + print(e3.value),
  FragmentSpread(e3) {
    var r2 = "..." + e3.name.value;
    if (hasItems(e3.directives)) {
      r2 += " " + e3.directives.map(y.Directive).join(" ");
    }
    return r2;
  },
  InlineFragment(e3) {
    var r2 = "...";
    if (e3.typeCondition) {
      r2 += " on " + e3.typeCondition.name.value;
    }
    if (hasItems(e3.directives)) {
      r2 += " " + e3.directives.map(y.Directive).join(" ");
    }
    return r2 + " " + print(e3.selectionSet);
  },
  FragmentDefinition(e3) {
    var r2 = "fragment " + e3.name.value;
    r2 += " on " + e3.typeCondition.name.value;
    if (hasItems(e3.directives)) {
      r2 += " " + e3.directives.map(y.Directive).join(" ");
    }
    return r2 + " " + print(e3.selectionSet);
  },
  Directive(e3) {
    var r2 = "@" + e3.name.value;
    if (hasItems(e3.arguments)) {
      r2 += "(" + e3.arguments.map(y.Argument).join(", ") + ")";
    }
    return r2;
  },
  NamedType: (e3) => e3.name.value,
  ListType: (e3) => "[" + print(e3.type) + "]",
  NonNullType: (e3) => print(e3.type) + "!"
};
function print(e3) {
  return y[e3.kind] ? y[e3.kind](e3) : "";
}

// ../../node_modules/wonka/dist/wonka.mjs
var teardownPlaceholder = () => {
};
var e2 = teardownPlaceholder;
function start(e3) {
  return {
    tag: 0,
    0: e3
  };
}
function push(e3) {
  return {
    tag: 1,
    0: e3
  };
}
var asyncIteratorSymbol = () => "function" == typeof Symbol && Symbol.asyncIterator || "@@asyncIterator";
var identity = (e3) => e3;
function filter(r2) {
  return (t2) => (i3) => {
    var a2 = e2;
    t2((e3) => {
      if (0 === e3) {
        i3(0);
      } else if (0 === e3.tag) {
        a2 = e3[0];
        i3(e3);
      } else if (!r2(e3[0])) {
        a2(0);
      } else {
        i3(e3);
      }
    });
  };
}
function map(e3) {
  return (r2) => (t2) => r2((r3) => {
    if (0 === r3 || 0 === r3.tag) {
      t2(r3);
    } else {
      t2(push(e3(r3[0])));
    }
  });
}
function mergeMap(r2) {
  return (t2) => (i3) => {
    var a2 = [];
    var f3 = e2;
    var n2 = false;
    var s2 = false;
    t2((t3) => {
      if (s2) {
      } else if (0 === t3) {
        s2 = true;
        if (!a2.length) {
          i3(0);
        }
      } else if (0 === t3.tag) {
        f3 = t3[0];
      } else {
        n2 = false;
        !function applyInnerSource(r3) {
          var t4 = e2;
          r3((e3) => {
            if (0 === e3) {
              if (a2.length) {
                var r4 = a2.indexOf(t4);
                if (r4 > -1) {
                  (a2 = a2.slice()).splice(r4, 1);
                }
                if (!a2.length) {
                  if (s2) {
                    i3(0);
                  } else if (!n2) {
                    n2 = true;
                    f3(0);
                  }
                }
              }
            } else if (0 === e3.tag) {
              a2.push(t4 = e3[0]);
              t4(0);
            } else if (a2.length) {
              i3(e3);
              t4(0);
            }
          });
        }(r2(t3[0]));
        if (!n2) {
          n2 = true;
          f3(0);
        }
      }
    });
    i3(start((e3) => {
      if (1 === e3) {
        if (!s2) {
          s2 = true;
          f3(1);
        }
        for (var r3 = 0, t3 = a2, i4 = a2.length; r3 < i4; r3++) {
          t3[r3](1);
        }
        a2.length = 0;
      } else {
        if (!s2 && !n2) {
          n2 = true;
          f3(0);
        } else {
          n2 = false;
        }
        for (var l3 = 0, u3 = a2, o2 = a2.length; l3 < o2; l3++) {
          u3[l3](0);
        }
      }
    }));
  };
}
function mergeAll(e3) {
  return mergeMap(identity)(e3);
}
function merge(e3) {
  return mergeAll(r(e3));
}
function onEnd(e3) {
  return (r2) => (t2) => {
    var i3 = false;
    r2((r3) => {
      if (i3) {
      } else if (0 === r3) {
        i3 = true;
        t2(0);
        e3();
      } else if (0 === r3.tag) {
        var a2 = r3[0];
        t2(start((r4) => {
          if (1 === r4) {
            i3 = true;
            a2(1);
            e3();
          } else {
            a2(r4);
          }
        }));
      } else {
        t2(r3);
      }
    });
  };
}
function onPush(e3) {
  return (r2) => (t2) => {
    var i3 = false;
    r2((r3) => {
      if (i3) {
      } else if (0 === r3) {
        i3 = true;
        t2(0);
      } else if (0 === r3.tag) {
        var a2 = r3[0];
        t2(start((e4) => {
          if (1 === e4) {
            i3 = true;
          }
          a2(e4);
        }));
      } else {
        e3(r3[0]);
        t2(r3);
      }
    });
  };
}
function onStart(e3) {
  return (r2) => (t2) => r2((r3) => {
    if (0 === r3) {
      t2(0);
    } else if (0 === r3.tag) {
      t2(r3);
      e3();
    } else {
      t2(r3);
    }
  });
}
function share(r2) {
  var t2 = [];
  var i3 = e2;
  var a2 = false;
  return (e3) => {
    t2.push(e3);
    if (1 === t2.length) {
      r2((e4) => {
        if (0 === e4) {
          for (var r3 = 0, f3 = t2, n2 = t2.length; r3 < n2; r3++) {
            f3[r3](0);
          }
          t2.length = 0;
        } else if (0 === e4.tag) {
          i3 = e4[0];
        } else {
          a2 = false;
          for (var s2 = 0, l3 = t2, u3 = t2.length; s2 < u3; s2++) {
            l3[s2](e4);
          }
        }
      });
    }
    e3(start((r3) => {
      if (1 === r3) {
        var f3 = t2.indexOf(e3);
        if (f3 > -1) {
          (t2 = t2.slice()).splice(f3, 1);
        }
        if (!t2.length) {
          i3(1);
        }
      } else if (!a2) {
        a2 = true;
        i3(0);
      }
    }));
  };
}
function switchMap(r2) {
  return (t2) => (i3) => {
    var a2 = e2;
    var f3 = e2;
    var n2 = false;
    var s2 = false;
    var l3 = false;
    var u3 = false;
    t2((t3) => {
      if (u3) {
      } else if (0 === t3) {
        u3 = true;
        if (!l3) {
          i3(0);
        }
      } else if (0 === t3.tag) {
        a2 = t3[0];
      } else {
        if (l3) {
          f3(1);
          f3 = e2;
        }
        if (!n2) {
          n2 = true;
          a2(0);
        } else {
          n2 = false;
        }
        !function applyInnerSource(e3) {
          l3 = true;
          e3((e4) => {
            if (!l3) {
            } else if (0 === e4) {
              l3 = false;
              if (u3) {
                i3(0);
              } else if (!n2) {
                n2 = true;
                a2(0);
              }
            } else if (0 === e4.tag) {
              s2 = false;
              (f3 = e4[0])(0);
            } else {
              i3(e4);
              if (!s2) {
                f3(0);
              } else {
                s2 = false;
              }
            }
          });
        }(r2(t3[0]));
      }
    });
    i3(start((e3) => {
      if (1 === e3) {
        if (!u3) {
          u3 = true;
          a2(1);
        }
        if (l3) {
          l3 = false;
          f3(1);
        }
      } else {
        if (!u3 && !n2) {
          n2 = true;
          a2(0);
        }
        if (l3 && !s2) {
          s2 = true;
          f3(0);
        }
      }
    }));
  };
}
function take(r2) {
  return (t2) => (i3) => {
    var a2 = e2;
    var f3 = false;
    var n2 = 0;
    t2((e3) => {
      if (f3) {
      } else if (0 === e3) {
        f3 = true;
        i3(0);
      } else if (0 === e3.tag) {
        if (r2 <= 0) {
          f3 = true;
          i3(0);
          e3[0](1);
        } else {
          a2 = e3[0];
        }
      } else if (n2++ < r2) {
        i3(e3);
        if (!f3 && n2 >= r2) {
          f3 = true;
          i3(0);
          a2(1);
        }
      } else {
        i3(e3);
      }
    });
    i3(start((e3) => {
      if (1 === e3 && !f3) {
        f3 = true;
        a2(1);
      } else if (0 === e3 && !f3 && n2 < r2) {
        a2(0);
      }
    }));
  };
}
function takeUntil(r2) {
  return (t2) => (i3) => {
    var a2 = e2;
    var f3 = e2;
    var n2 = false;
    t2((e3) => {
      if (n2) {
      } else if (0 === e3) {
        n2 = true;
        f3(1);
        i3(0);
      } else if (0 === e3.tag) {
        a2 = e3[0];
        r2((e4) => {
          if (0 === e4) {
          } else if (0 === e4.tag) {
            (f3 = e4[0])(0);
          } else {
            n2 = true;
            f3(1);
            a2(1);
            i3(0);
          }
        });
      } else {
        i3(e3);
      }
    });
    i3(start((e3) => {
      if (1 === e3 && !n2) {
        n2 = true;
        a2(1);
        f3(1);
      } else if (!n2) {
        a2(0);
      }
    }));
  };
}
function takeWhile(r2, t2) {
  return (i3) => (a2) => {
    var f3 = e2;
    var n2 = false;
    i3((e3) => {
      if (n2) {
      } else if (0 === e3) {
        n2 = true;
        a2(0);
      } else if (0 === e3.tag) {
        f3 = e3[0];
        a2(e3);
      } else if (!r2(e3[0])) {
        n2 = true;
        if (t2) {
          a2(e3);
        }
        a2(0);
        f3(1);
      } else {
        a2(e3);
      }
    });
  };
}
function lazy(e3) {
  return (r2) => e3()(r2);
}
function fromAsyncIterable(e3) {
  return (r2) => {
    var t2 = e3[asyncIteratorSymbol()] && e3[asyncIteratorSymbol()]() || e3;
    var i3 = false;
    var a2 = false;
    var f3 = false;
    var n2;
    r2(start(async (e4) => {
      if (1 === e4) {
        i3 = true;
        if (t2.return) {
          t2.return();
        }
      } else if (a2) {
        f3 = true;
      } else {
        for (f3 = a2 = true; f3 && !i3; ) {
          if ((n2 = await t2.next()).done) {
            i3 = true;
            if (t2.return) {
              await t2.return();
            }
            r2(0);
          } else {
            try {
              f3 = false;
              r2(push(n2.value));
            } catch (e5) {
              if (t2.throw) {
                if (i3 = !!(await t2.throw(e5)).done) {
                  r2(0);
                }
              } else {
                throw e5;
              }
            }
          }
        }
        a2 = false;
      }
    }));
  };
}
function fromIterable(e3) {
  if (e3[Symbol.asyncIterator]) {
    return fromAsyncIterable(e3);
  }
  return (r2) => {
    var t2 = e3[Symbol.iterator]();
    var i3 = false;
    var a2 = false;
    var f3 = false;
    var n2;
    r2(start((e4) => {
      if (1 === e4) {
        i3 = true;
        if (t2.return) {
          t2.return();
        }
      } else if (a2) {
        f3 = true;
      } else {
        for (f3 = a2 = true; f3 && !i3; ) {
          if ((n2 = t2.next()).done) {
            i3 = true;
            if (t2.return) {
              t2.return();
            }
            r2(0);
          } else {
            try {
              f3 = false;
              r2(push(n2.value));
            } catch (e5) {
              if (t2.throw) {
                if (i3 = !!t2.throw(e5).done) {
                  r2(0);
                }
              } else {
                throw e5;
              }
            }
          }
        }
        a2 = false;
      }
    }));
  };
}
var r = fromIterable;
function fromValue(e3) {
  return (r2) => {
    var t2 = false;
    r2(start((i3) => {
      if (1 === i3) {
        t2 = true;
      } else if (!t2) {
        t2 = true;
        r2(push(e3));
        r2(0);
      }
    }));
  };
}
function make(e3) {
  return (r2) => {
    var t2 = false;
    var i3 = e3({
      next(e4) {
        if (!t2) {
          r2(push(e4));
        }
      },
      complete() {
        if (!t2) {
          t2 = true;
          r2(0);
        }
      }
    });
    r2(start((e4) => {
      if (1 === e4 && !t2) {
        t2 = true;
        i3();
      }
    }));
  };
}
function makeSubject() {
  var e3;
  var r2;
  return {
    source: share(make((t2) => {
      e3 = t2.next;
      r2 = t2.complete;
      return teardownPlaceholder;
    })),
    next(r3) {
      if (e3) {
        e3(r3);
      }
    },
    complete() {
      if (r2) {
        r2();
      }
    }
  };
}
function subscribe(r2) {
  return (t2) => {
    var i3 = e2;
    var a2 = false;
    t2((e3) => {
      if (0 === e3) {
        a2 = true;
      } else if (0 === e3.tag) {
        (i3 = e3[0])(0);
      } else if (!a2) {
        r2(e3[0]);
        i3(0);
      }
    });
    return {
      unsubscribe() {
        if (!a2) {
          a2 = true;
          i3(1);
        }
      }
    };
  };
}
function publish(e3) {
  subscribe((e4) => {
  })(e3);
}
function toPromise(r2) {
  return new Promise((t2) => {
    var i3 = e2;
    var a2;
    r2((e3) => {
      if (0 === e3) {
        Promise.resolve(a2).then(t2);
      } else if (0 === e3.tag) {
        (i3 = e3[0])(0);
      } else {
        a2 = e3[0];
        i3(0);
      }
    });
  });
}

// ../../node_modules/@urql/core/dist/urql-core-chunk.mjs
var rehydrateGraphQlError = (e3) => {
  if (e3 && e3.message && (e3.extensions || "GraphQLError" === e3.name)) {
    return e3;
  } else if ("object" == typeof e3 && e3.message) {
    return new GraphQLError(e3.message, e3.nodes, e3.source, e3.positions, e3.path, e3, e3.extensions || {});
  } else {
    return new GraphQLError(e3);
  }
};
var CombinedError = class extends Error {
  constructor(r2) {
    var e3 = (r2.graphQLErrors || []).map(rehydrateGraphQlError);
    var t2 = ((r3, e4) => {
      var t3 = "";
      if (r3) {
        return `[Network] ${r3.message}`;
      }
      if (e4) {
        for (var a2 of e4) {
          if (t3) {
            t3 += "\n";
          }
          t3 += `[GraphQL] ${a2.message}`;
        }
      }
      return t3;
    })(r2.networkError, e3);
    super(t2);
    this.name = "CombinedError";
    this.message = t2;
    this.graphQLErrors = e3;
    this.networkError = r2.networkError;
    this.response = r2.response;
  }
  toString() {
    return this.message;
  }
};
var phash = (r2, e3) => {
  var t2 = 0 | (e3 || 5381);
  for (var a2 = 0, o2 = 0 | r2.length; a2 < o2; a2++) {
    t2 = (t2 << 5) + t2 + r2.charCodeAt(a2);
  }
  return t2;
};
var i2 = /* @__PURE__ */ new Set();
var f2 = /* @__PURE__ */ new WeakMap();
var stringify = (r2) => {
  if (null === r2 || i2.has(r2)) {
    return "null";
  } else if ("object" != typeof r2) {
    return JSON.stringify(r2) || "";
  } else if (r2.toJSON) {
    return stringify(r2.toJSON());
  } else if (Array.isArray(r2)) {
    var e3 = "[";
    for (var t2 of r2) {
      if (e3.length > 1) {
        e3 += ",";
      }
      e3 += stringify(t2) || "null";
    }
    return e3 += "]";
  } else if (v2 !== NoopConstructor && r2 instanceof v2 || l2 !== NoopConstructor && r2 instanceof l2) {
    return "null";
  }
  var a2 = Object.keys(r2).sort();
  if (!a2.length && r2.constructor && r2.constructor !== Object) {
    var o2 = f2.get(r2) || Math.random().toString(36).slice(2);
    f2.set(r2, o2);
    return stringify({
      __key: o2
    });
  }
  i2.add(r2);
  var n2 = "{";
  for (var s2 of a2) {
    var c3 = stringify(r2[s2]);
    if (c3) {
      if (n2.length > 1) {
        n2 += ",";
      }
      n2 += stringify(s2) + ":" + c3;
    }
  }
  i2.delete(r2);
  return n2 += "}";
};
var extract = (r2, e3, t2) => {
  if (null == t2 || "object" != typeof t2 || t2.toJSON || i2.has(t2)) {
  } else if (Array.isArray(t2)) {
    for (var a2 = 0, o2 = t2.length; a2 < o2; a2++) {
      extract(r2, `${e3}.${a2}`, t2[a2]);
    }
  } else if (t2 instanceof v2 || t2 instanceof l2) {
    r2.set(e3, t2);
  } else {
    i2.add(t2);
    for (var n2 of Object.keys(t2)) {
      extract(r2, `${e3}.${n2}`, t2[n2]);
    }
  }
};
var stringifyVariables = (r2) => {
  i2.clear();
  return stringify(r2);
};
var NoopConstructor = class {
};
var v2 = "undefined" != typeof File ? File : NoopConstructor;
var l2 = "undefined" != typeof Blob ? Blob : NoopConstructor;
var c2 = /("{3}[\s\S]*"{3}|"(?:\\.|[^"])*")/g;
var d2 = /(?:#[^\n\r]+)?(?:[\r\n]+|$)/g;
var replaceOutsideStrings = (r2, e3) => e3 % 2 == 0 ? r2.replace(d2, "\n") : r2;
var sanitizeDocument = (r2) => r2.split(c2).map(replaceOutsideStrings).join("").trim();
var p2 = /* @__PURE__ */ new Map();
var u2 = /* @__PURE__ */ new Map();
var stringifyDocument = (r2) => {
  var t2;
  if ("string" == typeof r2) {
    t2 = sanitizeDocument(r2);
  } else if (r2.loc && u2.get(r2.__key) === r2) {
    t2 = r2.loc.source.body;
  } else {
    t2 = p2.get(r2) || sanitizeDocument(print(r2));
    p2.set(r2, t2);
  }
  if ("string" != typeof r2 && !r2.loc) {
    r2.loc = {
      start: 0,
      end: t2.length,
      source: {
        body: t2,
        name: "gql",
        locationOffset: {
          line: 1,
          column: 1
        }
      }
    };
  }
  return t2;
};
var hashDocument = (r2) => {
  var e3 = phash(stringifyDocument(r2));
  if (r2.definitions) {
    var t2 = getOperationName(r2);
    if (t2) {
      e3 = phash(`
# ${t2}`, e3);
    }
  }
  return e3;
};
var keyDocument = (r2) => {
  var e3;
  var a2;
  if ("string" == typeof r2) {
    e3 = hashDocument(r2);
    a2 = u2.get(e3) || parse(r2, {
      noLocation: true
    });
  } else {
    e3 = r2.__key || hashDocument(r2);
    a2 = u2.get(e3) || r2;
  }
  if (!a2.loc) {
    stringifyDocument(a2);
  }
  a2.__key = e3;
  u2.set(e3, a2);
  return a2;
};
var createRequest = (r2, e3, t2) => {
  var a2 = e3 || {};
  var o2 = keyDocument(r2);
  var n2 = stringifyVariables(a2);
  var s2 = o2.__key;
  if ("{}" !== n2) {
    s2 = phash(n2, s2);
  }
  return {
    key: s2,
    query: o2,
    variables: a2,
    extensions: t2
  };
};
var getOperationName = (r2) => {
  for (var e3 of r2.definitions) {
    if (e3.kind === e.OPERATION_DEFINITION) {
      return e3.name ? e3.name.value : void 0;
    }
  }
};
var getOperationType = (r2) => {
  for (var e3 of r2.definitions) {
    if (e3.kind === e.OPERATION_DEFINITION) {
      return e3.operation;
    }
  }
};
var makeResult = (r2, e3, t2) => {
  if (!("data" in e3) && !("errors" in e3)) {
    throw new Error("No Content");
  }
  var a2 = "subscription" === r2.kind;
  return {
    operation: r2,
    data: e3.data,
    error: Array.isArray(e3.errors) ? new CombinedError({
      graphQLErrors: e3.errors,
      response: t2
    }) : void 0,
    extensions: e3.extensions ? {
      ...e3.extensions
    } : void 0,
    hasNext: null == e3.hasNext ? a2 : e3.hasNext,
    stale: false
  };
};
var deepMerge = (r2, e3) => {
  if ("object" == typeof r2 && null != r2) {
    if (!r2.constructor || r2.constructor === Object || Array.isArray(r2)) {
      r2 = Array.isArray(r2) ? [...r2] : {
        ...r2
      };
      for (var t2 of Object.keys(e3)) {
        r2[t2] = deepMerge(r2[t2], e3[t2]);
      }
      return r2;
    }
  }
  return e3;
};
var mergeResultPatch = (r2, e3, t2) => {
  var a2 = r2.error ? r2.error.graphQLErrors : [];
  var o2 = !!r2.extensions || !!e3.extensions;
  var n2 = {
    ...r2.extensions,
    ...e3.extensions
  };
  var s2 = e3.incremental;
  if ("path" in e3) {
    s2 = [e3];
  }
  var i3 = {
    data: r2.data
  };
  if (s2) {
    for (var f3 of s2) {
      if (Array.isArray(f3.errors)) {
        a2.push(...f3.errors);
      }
      if (f3.extensions) {
        Object.assign(n2, f3.extensions);
        o2 = true;
      }
      var v3 = "data";
      var l3 = i3;
      for (var c3 = 0, d3 = f3.path.length; c3 < d3; v3 = f3.path[c3++]) {
        l3 = l3[v3] = Array.isArray(l3[v3]) ? [...l3[v3]] : {
          ...l3[v3]
        };
      }
      if (f3.items) {
        var p3 = +v3 >= 0 ? v3 : 0;
        for (var u3 = 0, y3 = f3.items.length; u3 < y3; u3++) {
          l3[p3 + u3] = deepMerge(l3[p3 + u3], f3.items[u3]);
        }
      } else if (void 0 !== f3.data) {
        l3[v3] = deepMerge(l3[v3], f3.data);
      }
    }
  } else {
    i3.data = e3.data || r2.data;
    a2 = e3.errors || a2;
  }
  return {
    operation: r2.operation,
    data: i3.data,
    error: a2.length ? new CombinedError({
      graphQLErrors: a2,
      response: t2
    }) : void 0,
    extensions: o2 ? n2 : void 0,
    hasNext: null != e3.hasNext ? e3.hasNext : r2.hasNext,
    stale: false
  };
};
var makeErrorResult = (r2, e3, t2) => ({
  operation: r2,
  data: void 0,
  error: new CombinedError({
    networkError: e3,
    response: t2
  }),
  extensions: void 0,
  hasNext: false,
  stale: false
});
function makeFetchBody(r2) {
  return {
    query: r2.extensions && r2.extensions.persistedQuery && !r2.extensions.persistedQuery.miss ? void 0 : stringifyDocument(r2.query),
    operationName: getOperationName(r2.query),
    variables: r2.variables || void 0,
    extensions: r2.extensions
  };
}
var makeFetchURL = (r2, e3) => {
  var t2 = "query" === r2.kind && r2.context.preferGetMethod;
  if (!t2 || !e3) {
    return r2.context.url;
  }
  var a2 = new URL(r2.context.url);
  for (var o2 in e3) {
    var n2 = e3[o2];
    if (n2) {
      a2.searchParams.set(o2, "object" == typeof n2 ? stringifyVariables(n2) : n2);
    }
  }
  var s2 = a2.toString();
  if (s2.length > 2047 && "force" !== t2) {
    r2.context.preferGetMethod = false;
    return r2.context.url;
  }
  return s2;
};
var serializeBody = (r2, e3) => {
  if (e3 && !("query" === r2.kind && !!r2.context.preferGetMethod)) {
    var t2 = stringifyVariables(e3);
    var a2 = ((r3) => {
      var e4 = /* @__PURE__ */ new Map();
      if (v2 !== NoopConstructor || l2 !== NoopConstructor) {
        i2.clear();
        extract(e4, "variables", r3);
      }
      return e4;
    })(e3.variables);
    if (a2.size) {
      var o2 = new FormData();
      o2.append("operations", t2);
      o2.append("map", stringifyVariables({
        ...[...a2.keys()].map((r3) => [r3])
      }));
      var n2 = 0;
      for (var s2 of a2.values()) {
        o2.append("" + n2++, s2);
      }
      return o2;
    }
    return t2;
  }
};
var makeFetchOptions = (r2, e3) => {
  var t2 = {
    accept: "subscription" === r2.kind ? "text/event-stream, multipart/mixed" : "application/graphql-response+json, application/graphql+json, application/json, text/event-stream, multipart/mixed"
  };
  var a2 = ("function" == typeof r2.context.fetchOptions ? r2.context.fetchOptions() : r2.context.fetchOptions) || {};
  if (a2.headers) {
    for (var o2 in a2.headers) {
      t2[o2.toLowerCase()] = a2.headers[o2];
    }
  }
  var n2 = serializeBody(r2, e3);
  if ("string" == typeof n2 && !t2["content-type"]) {
    t2["content-type"] = "application/json";
  }
  return {
    ...a2,
    method: n2 ? "POST" : "GET",
    body: n2,
    headers: t2
  };
};
var y2 = "undefined" != typeof TextDecoder ? new TextDecoder() : null;
var h = /boundary="?([^=";]+)"?/i;
var x = /data: ?([^\n]+)/;
var toString = (r2) => "Buffer" === r2.constructor.name ? r2.toString() : y2.decode(r2);
async function* streamBody(r2) {
  if (r2.body[Symbol.asyncIterator]) {
    for await (var e3 of r2.body) {
      yield toString(e3);
    }
  } else {
    var t2 = r2.body.getReader();
    var a2;
    try {
      while (!(a2 = await t2.read()).done) {
        yield toString(a2.value);
      }
    } finally {
      t2.cancel();
    }
  }
}
async function* split(r2, e3) {
  var t2 = "";
  var a2;
  for await (var o2 of r2) {
    t2 += o2;
    while ((a2 = t2.indexOf(e3)) > -1) {
      yield t2.slice(0, a2);
      t2 = t2.slice(a2 + e3.length);
    }
  }
}
async function* fetchOperation(r2, e3, t2) {
  var a2 = true;
  var o2 = null;
  var n2;
  try {
    yield await Promise.resolve();
    var s2 = (n2 = await (r2.context.fetch || fetch)(e3, t2)).headers.get("Content-Type") || "";
    var i3;
    if (/multipart\/mixed/i.test(s2)) {
      i3 = async function* parseMultipartMixed(r3, e4) {
        var t3 = r3.match(h);
        var a3 = "--" + (t3 ? t3[1] : "-");
        var o3 = true;
        var n3;
        for await (var s3 of split(streamBody(e4), "\r\n" + a3)) {
          if (o3) {
            o3 = false;
            var i4 = s3.indexOf(a3);
            if (i4 > -1) {
              s3 = s3.slice(i4 + a3.length);
            } else {
              continue;
            }
          }
          try {
            yield n3 = JSON.parse(s3.slice(s3.indexOf("\r\n\r\n") + 4));
          } catch (r4) {
            if (!n3) {
              throw r4;
            }
          }
          if (n3 && false === n3.hasNext) {
            break;
          }
        }
        if (n3 && false !== n3.hasNext) {
          yield {
            hasNext: false
          };
        }
      }(s2, n2);
    } else if (/text\/event-stream/i.test(s2)) {
      i3 = async function* parseEventStream(r3) {
        var e4;
        for await (var t3 of split(streamBody(r3), "\n\n")) {
          var a3 = t3.match(x);
          if (a3) {
            var o3 = a3[1];
            try {
              yield e4 = JSON.parse(o3);
            } catch (r4) {
              if (!e4) {
                throw r4;
              }
            }
            if (e4 && false === e4.hasNext) {
              break;
            }
          }
        }
        if (e4 && false !== e4.hasNext) {
          yield {
            hasNext: false
          };
        }
      }(n2);
    } else if (!/text\//i.test(s2)) {
      i3 = async function* parseJSON(r3) {
        yield JSON.parse(await r3.text());
      }(n2);
    } else {
      throw new Error(await n2.text());
    }
    for await (var f3 of i3) {
      o2 = o2 ? mergeResultPatch(o2, f3, n2) : makeResult(r2, f3, n2);
      a2 = false;
      yield o2;
      a2 = true;
    }
    if (!o2) {
      yield o2 = makeResult(r2, {}, n2);
    }
  } catch (e4) {
    if (!a2) {
      throw e4;
    }
    yield makeErrorResult(r2, n2 && (n2.status < 200 || n2.status >= 300) && n2.statusText ? new Error(n2.statusText) : e4, n2);
  }
}
function makeFetchSource(r2, e3, t2) {
  var a2;
  if ("undefined" != typeof AbortController) {
    t2.signal = (a2 = new AbortController()).signal;
  }
  return onEnd(() => {
    if (a2) {
      a2.abort();
    }
  })(filter((r3) => !!r3)(fromAsyncIterable(fetchOperation(r2, e3, t2))));
}

// ../../node_modules/@urql/core/dist/urql-core.mjs
var collectTypes = (e3, r2) => {
  if (Array.isArray(e3)) {
    for (var t2 of e3) {
      collectTypes(t2, r2);
    }
  } else if ("object" == typeof e3 && null !== e3) {
    for (var n2 in e3) {
      if ("__typename" === n2 && "string" == typeof e3[n2]) {
        r2.add(e3[n2]);
      } else {
        collectTypes(e3[n2], r2);
      }
    }
  }
  return r2;
};
var formatNode = (r2) => {
  var t2 = false;
  if ("definitions" in r2) {
    var n2 = [];
    for (var a2 of r2.definitions) {
      var o2 = formatNode(a2);
      t2 = t2 || o2 !== a2;
      n2.push(o2);
    }
    if (t2) {
      return {
        ...r2,
        definitions: n2
      };
    }
  } else if ("selectionSet" in r2) {
    var i3 = [];
    var s2 = r2.kind === e.OPERATION_DEFINITION;
    if (r2.selectionSet) {
      for (var c3 of r2.selectionSet.selections || []) {
        s2 = s2 || c3.kind === e.FIELD && "__typename" === c3.name.value && !c3.alias;
        var u3 = formatNode(c3);
        t2 = t2 || u3 !== c3;
        i3.push(u3);
      }
      if (!s2) {
        t2 = true;
        i3.push({
          kind: e.FIELD,
          name: {
            kind: e.NAME,
            value: "__typename"
          }
        });
      }
      if (t2) {
        return {
          ...r2,
          selectionSet: {
            ...r2.selectionSet,
            selections: i3
          }
        };
      }
    }
  }
  return r2;
};
var I = /* @__PURE__ */ new Map();
var formatDocument = (e3) => {
  var t2 = keyDocument(e3);
  var n2 = I.get(t2.__key);
  if (!n2) {
    I.set(t2.__key, n2 = formatNode(t2));
    Object.defineProperty(n2, "__key", {
      value: t2.__key,
      enumerable: false
    });
  }
  return n2;
};
var maskTypename = (e3, r2) => {
  if (!e3 || "object" != typeof e3) {
    return e3;
  } else if (Array.isArray(e3)) {
    return e3.map((e4) => maskTypename(e4));
  } else if (e3 && "object" == typeof e3 && (r2 || "__typename" in e3)) {
    var t2 = {};
    for (var n2 in e3) {
      if ("__typename" === n2) {
        Object.defineProperty(t2, "__typename", {
          enumerable: false,
          value: e3.__typename
        });
      } else {
        t2[n2] = maskTypename(e3[n2]);
      }
    }
    return t2;
  } else {
    return e3;
  }
};
function withPromise(e3) {
  var source$ = (r2) => e3(r2);
  source$.toPromise = () => toPromise(take(1)(filter((e4) => !e4.stale && !e4.hasNext)(source$)));
  source$.then = (e4, r2) => source$.toPromise().then(e4, r2);
  source$.subscribe = (e4) => subscribe(e4)(source$);
  return source$;
}
function makeOperation(e3, r2, t2) {
  return {
    ...r2,
    kind: e3,
    context: r2.context ? {
      ...r2.context,
      ...t2
    } : t2 || r2.context
  };
}
var addMetadata = (e3, r2) => makeOperation(e3.kind, e3, {
  meta: {
    ...e3.context.meta,
    ...r2
  }
});
var noop = () => {
};
var shouldSkip = ({ kind: e3 }) => "mutation" !== e3 && "query" !== e3;
var cacheExchange = ({ forward: e3, client: r2, dispatchDebug: t2 }) => {
  var n2 = /* @__PURE__ */ new Map();
  var a2 = /* @__PURE__ */ new Map();
  var mapTypeNames = (e4) => {
    var r3 = makeOperation(e4.kind, e4);
    r3.query = formatDocument(e4.query);
    return r3;
  };
  var isOperationCached = (e4) => "query" === e4.kind && "network-only" !== e4.context.requestPolicy && ("cache-only" === e4.context.requestPolicy || n2.has(e4.key));
  return (o2) => {
    var i3 = map((e4) => {
      var a3 = n2.get(e4.key);
      "production" !== process.env.NODE_ENV && t2({
        operation: e4,
        ...a3 ? {
          type: "cacheHit",
          message: "The result was successfully retried from the cache"
        } : {
          type: "cacheMiss",
          message: "The result could not be retrieved from the cache"
        },
        source: "cacheExchange"
      });
      var o3 = a3;
      if ("production" !== process.env.NODE_ENV) {
        o3 = {
          ...o3,
          operation: "production" !== process.env.NODE_ENV ? addMetadata(e4, {
            cacheOutcome: a3 ? "hit" : "miss"
          }) : e4
        };
      }
      if ("cache-and-network" === e4.context.requestPolicy) {
        o3.stale = true;
        reexecuteOperation(r2, e4);
      }
      return o3;
    })(filter((e4) => !shouldSkip(e4) && isOperationCached(e4))(o2));
    var s2 = onPush((e4) => {
      var { operation: o3 } = e4;
      if (!o3) {
        return;
      }
      var i4 = o3.context.additionalTypenames || [];
      if ("subscription" !== e4.operation.kind) {
        i4 = ((e5) => [...collectTypes(e5, /* @__PURE__ */ new Set())])(e4.data).concat(i4);
      }
      if ("mutation" === e4.operation.kind || "subscription" === e4.operation.kind) {
        var s3 = /* @__PURE__ */ new Set();
        "production" !== process.env.NODE_ENV && t2({
          type: "cacheInvalidation",
          message: `The following typenames have been invalidated: ${i4}`,
          operation: o3,
          data: {
            typenames: i4,
            response: e4
          },
          source: "cacheExchange"
        });
        for (var c3 = 0; c3 < i4.length; c3++) {
          var u3 = i4[c3];
          var p3 = a2.get(u3);
          if (!p3) {
            a2.set(u3, p3 = /* @__PURE__ */ new Set());
          }
          for (var d3 of p3.values()) {
            s3.add(d3);
          }
          p3.clear();
        }
        for (var f3 of s3.values()) {
          if (n2.has(f3)) {
            o3 = n2.get(f3).operation;
            n2.delete(f3);
            reexecuteOperation(r2, o3);
          }
        }
      } else if ("query" === o3.kind && e4.data) {
        n2.set(o3.key, e4);
        for (var v3 = 0; v3 < i4.length; v3++) {
          var l3 = i4[v3];
          var h2 = a2.get(l3);
          if (!h2) {
            a2.set(l3, h2 = /* @__PURE__ */ new Set());
          }
          h2.add(o3.key);
        }
      }
    })(e3(filter((e4) => "query" !== e4.kind || "cache-only" !== e4.context.requestPolicy)(map((e4) => "production" !== process.env.NODE_ENV ? addMetadata(e4, {
      cacheOutcome: "miss"
    }) : e4)(merge([map(mapTypeNames)(filter((e4) => !shouldSkip(e4) && !isOperationCached(e4))(o2)), filter((e4) => shouldSkip(e4))(o2)])))));
    return merge([i3, s2]);
  };
};
var reexecuteOperation = (e3, r2) => e3.reexecuteOperation(makeOperation(r2.kind, r2, {
  requestPolicy: "network-only"
}));
var subscriptionExchange = ({ forwardSubscription: e3, enableAllOperations: r2, isSubscriptionOperation: t2 }) => ({ client: n2, forward: i3 }) => {
  var u3 = t2 || ((e4) => "subscription" === e4.kind || !!r2 && ("query" === e4.kind || "mutation" === e4.kind));
  return (r3) => {
    var t3 = mergeMap((t4) => {
      var { key: i4 } = t4;
      var u4 = filter((e4) => "teardown" === e4.kind && e4.key === i4)(r3);
      return takeUntil(u4)(((r4) => {
        var t5 = e3(makeFetchBody(r4), r4);
        return make(({ next: e4, complete: a2 }) => {
          var i5 = false;
          var u5;
          var p4;
          Promise.resolve().then(() => {
            if (i5) {
              return;
            }
            u5 = t5.subscribe({
              next(t6) {
                e4(p4 = p4 ? mergeResultPatch(p4, t6) : makeResult(r4, t6));
              },
              error(t6) {
                e4(makeErrorResult(r4, t6));
              },
              complete() {
                if (!i5) {
                  i5 = true;
                  if ("subscription" === r4.kind) {
                    n2.reexecuteOperation(makeOperation("teardown", r4, r4.context));
                  }
                  if (p4 && p4.hasNext) {
                    e4(mergeResultPatch(p4, {
                      hasNext: false
                    }));
                  }
                  a2();
                }
              }
            });
          });
          return () => {
            i5 = true;
            if (u5) {
              u5.unsubscribe();
            }
          };
        });
      })(t4));
    })(filter((e4) => "teardown" !== e4.kind && u3(e4))(r3));
    var p3 = i3(filter((e4) => "teardown" === e4.kind || !u3(e4))(r3));
    return merge([t3, p3]);
  };
};
var fetchExchange = ({ forward: e3, dispatchDebug: r2 }) => (t2) => {
  var n2 = mergeMap((e4) => {
    var n3 = makeFetchBody(e4);
    var o3 = makeFetchURL(e4, n3);
    var i3 = makeFetchOptions(e4, n3);
    "production" !== process.env.NODE_ENV && r2({
      type: "fetchRequest",
      message: "A fetch request is being executed.",
      operation: e4,
      data: {
        url: o3,
        fetchOptions: i3
      },
      source: "fetchExchange"
    });
    var s2 = takeUntil(filter((r3) => "teardown" === r3.kind && r3.key === e4.key)(t2))(makeFetchSource(e4, o3, i3));
    if ("production" !== process.env.NODE_ENV) {
      return onPush((t3) => {
        var n4 = !t3.data ? t3.error : void 0;
        "production" !== process.env.NODE_ENV && r2({
          type: n4 ? "fetchError" : "fetchSuccess",
          message: `A ${n4 ? "failed" : "successful"} fetch response has been returned.`,
          operation: e4,
          data: {
            url: o3,
            fetchOptions: i3,
            value: n4 || t3
          },
          source: "fetchExchange"
        });
      })(s2);
    }
    return s2;
  })(filter((e4) => "teardown" !== e4.kind && ("subscription" !== e4.kind || !!e4.context.fetchSubscriptions))(t2));
  var o2 = e3(filter((e4) => "teardown" === e4.kind || "subscription" === e4.kind && !e4.context.fetchSubscriptions)(t2));
  return merge([n2, o2]);
};
var composeExchanges = (e3) => ({ client: r2, forward: t2, dispatchDebug: n2 }) => e3.reduceRight((e4, t3) => {
  var a2 = false;
  return t3({
    client: r2,
    forward(r3) {
      if ("production" !== process.env.NODE_ENV) {
        if (a2) {
          throw new Error("forward() must only be called once in each Exchange.");
        }
        a2 = true;
      }
      return share(e4(share(r3)));
    },
    dispatchDebug(e5) {
      "production" !== process.env.NODE_ENV && n2({
        timestamp: Date.now(),
        source: t3.name,
        ...e5
      });
    }
  });
}, t2);
var fallbackExchange = ({ dispatchDebug: e3 }) => (r2) => {
  if ("production" !== process.env.NODE_ENV) {
    r2 = onPush((r3) => {
      if ("teardown" !== r3.kind && "production" !== process.env.NODE_ENV) {
        var t2 = `No exchange has handled operations of kind "${r3.kind}". Check whether you've added an exchange responsible for these operations.`;
        "production" !== process.env.NODE_ENV && e3({
          type: "fallbackCatch",
          message: t2,
          operation: r3,
          source: "fallbackExchange"
        });
        console.warn(t2);
      }
    })(r2);
  }
  return filter((e4) => false)(r2);
};
var C = function Client(e3) {
  if ("production" !== process.env.NODE_ENV && !e3.url) {
    throw new Error("You are creating an urql-client without a url.");
  }
  var r2 = 0;
  var t2 = /* @__PURE__ */ new Map();
  var n2 = /* @__PURE__ */ new Map();
  var a2 = /* @__PURE__ */ new Set();
  var o2 = [];
  var i3 = {
    url: e3.url,
    fetchSubscriptions: e3.fetchSubscriptions,
    fetchOptions: e3.fetchOptions,
    fetch: e3.fetch,
    preferGetMethod: !!e3.preferGetMethod,
    requestPolicy: e3.requestPolicy || "cache-first"
  };
  var s2 = makeSubject();
  function nextOperation(e4) {
    if ("mutation" === e4.kind || "teardown" === e4.kind || !a2.has(e4.key)) {
      if ("teardown" === e4.kind) {
        a2.delete(e4.key);
      } else if ("mutation" !== e4.kind) {
        a2.add(e4.key);
      }
      s2.next(e4);
    }
  }
  var c3 = false;
  function dispatchOperation(e4) {
    if (e4) {
      nextOperation(e4);
    }
    if (!c3) {
      c3 = true;
      while (c3 && (e4 = o2.shift())) {
        nextOperation(e4);
      }
      c3 = false;
    }
  }
  var makeResultSource = (r3) => {
    var i4 = takeUntil(filter((e4) => "teardown" === e4.kind && e4.key === r3.key)(s2.source))(filter((e4) => e4.operation.kind === r3.kind && e4.operation.key === r3.key && (!e4.operation.context._instance || e4.operation.context._instance === r3.context._instance))(g2));
    if ("query" !== r3.kind) {
      i4 = takeWhile((e4) => !!e4.hasNext, true)(i4);
    } else {
      i4 = switchMap((e4) => {
        var t3 = fromValue(e4);
        return e4.stale || e4.hasNext ? t3 : merge([t3, map(() => {
          e4.stale = true;
          return e4;
        })(take(1)(filter((e5) => e5.key === r3.key)(s2.source)))]);
      })(i4);
    }
    if ("mutation" !== r3.kind) {
      i4 = onEnd(() => {
        a2.delete(r3.key);
        t2.delete(r3.key);
        n2.delete(r3.key);
        c3 = false;
        for (var e4 = o2.length - 1; e4 >= 0; e4--) {
          if (o2[e4].key === r3.key) {
            o2.splice(e4, 1);
          }
        }
        nextOperation(makeOperation("teardown", r3, r3.context));
      })(onPush((e4) => {
        if (e4.stale) {
          for (var n3 of o2) {
            if (n3.key === e4.operation.key) {
              a2.delete(n3.key);
              break;
            }
          }
        } else if (!e4.hasNext) {
          a2.delete(r3.key);
        }
        t2.set(r3.key, e4);
      })(i4));
    } else {
      i4 = onStart(() => {
        nextOperation(r3);
      })(i4);
    }
    if (e3.maskTypename) {
      i4 = map((e4) => ({
        ...e4,
        data: maskTypename(e4.data, true)
      }))(i4);
    }
    return share(i4);
  };
  var u3 = this instanceof Client ? this : Object.create(Client.prototype);
  var p3 = Object.assign(u3, {
    suspense: !!e3.suspense,
    operations$: s2.source,
    reexecuteOperation(e4) {
      if ("teardown" === e4.kind) {
        dispatchOperation(e4);
      } else if ("mutation" === e4.kind || n2.has(e4.key)) {
        o2.push(e4);
        Promise.resolve().then(dispatchOperation);
      }
    },
    createRequestOperation(e4, t3, n3) {
      if (!n3) {
        n3 = {};
      }
      var a3;
      if ("production" !== process.env.NODE_ENV && "teardown" !== e4 && (a3 = getOperationType(t3.query)) !== e4) {
        throw new Error(`Expected operation of type "${e4}" but found "${a3}"`);
      }
      return makeOperation(e4, t3, {
        _instance: "mutation" === e4 ? r2 = r2 + 1 | 0 : void 0,
        ...i3,
        ...n3,
        requestPolicy: n3.requestPolicy || i3.requestPolicy,
        suspense: n3.suspense || false !== n3.suspense && p3.suspense
      });
    },
    executeRequestOperation(e4) {
      if ("mutation" === e4.kind) {
        return withPromise(makeResultSource(e4));
      }
      return withPromise(lazy(() => {
        var r3 = n2.get(e4.key);
        if (!r3) {
          n2.set(e4.key, r3 = makeResultSource(e4));
        }
        r3 = onStart(() => {
          dispatchOperation(e4);
        })(r3);
        var a3 = t2.get(e4.key);
        if ("query" === e4.kind && a3 && (a3.stale || a3.hasNext)) {
          return switchMap(fromValue)(merge([r3, filter((r4) => r4 === t2.get(e4.key))(fromValue(a3))]));
        } else {
          return r3;
        }
      }));
    },
    executeQuery(e4, r3) {
      var t3 = p3.createRequestOperation("query", e4, r3);
      return p3.executeRequestOperation(t3);
    },
    executeSubscription(e4, r3) {
      var t3 = p3.createRequestOperation("subscription", e4, r3);
      return p3.executeRequestOperation(t3);
    },
    executeMutation(e4, r3) {
      var t3 = p3.createRequestOperation("mutation", e4, r3);
      return p3.executeRequestOperation(t3);
    },
    readQuery(e4, r3, t3) {
      var n3 = null;
      subscribe((e5) => {
        n3 = e5;
      })(p3.query(e4, r3, t3)).unsubscribe();
      return n3;
    },
    query: (e4, r3, t3) => p3.executeQuery(createRequest(e4, r3), t3),
    subscription: (e4, r3, t3) => p3.executeSubscription(createRequest(e4, r3), t3),
    mutation: (e4, r3, t3) => p3.executeMutation(createRequest(e4, r3), t3)
  });
  var d3 = noop;
  if ("production" !== process.env.NODE_ENV) {
    var { next: l3, source: x2 } = makeSubject();
    p3.subscribeToDebugTarget = (e4) => subscribe(e4)(x2);
    d3 = l3;
  }
  var w = composeExchanges(e3.exchanges);
  var g2 = share(w({
    client: p3,
    dispatchDebug: d3,
    forward: fallbackExchange({
      dispatchDebug: d3
    })
  })(s2.source));
  publish(g2);
  return p3;
};
var j = C;

// src/altGraphqlClient.ts
var import_exchange_auth = require("@urql/exchange-auth");
var import_graphql_ws = require("graphql-ws");
var import_jwt_decode = __toESM(require("jwt-decode"));
var useAltGraphQLClient = (config) => {
  let exchanges = [cacheExchange, fetchExchange];
  if (config?.auth) {
    const { auth } = config;
    const authEx = (0, import_exchange_auth.authExchange)(async (utils) => {
      const token = auth.accessToken;
      return {
        addAuthToOperation(operation) {
          if (token) {
            return utils.appendHeaders(operation, {
              Authorization: `Bearer ${token}`
            });
          }
          return operation;
        },
        willAuthError(_operation) {
          if (!token) {
            return true;
          }
          try {
            const decodedToken = (0, import_jwt_decode.default)(token);
            const currentTime = Math.floor(Date.now() / 1e3);
            const expiresIn = decodedToken.exp - currentTime;
            const expirationThreshold = 30;
            return expiresIn <= expirationThreshold;
          } catch (e3) {
            console.error("Error decoding JWT token:", e3);
            return true;
          }
        },
        didAuthError(error2, _operation) {
          return error2.graphQLErrors.some(
            (e3) => e3.extensions?.code === "FORBIDDEN" || e3.message?.toLowerCase().includes("expired token")
          );
        },
        async refreshAuth() {
          if (!auth.accessToken)
            return;
          try {
            await auth.refreshAccessToken();
          } catch (e3) {
            console.log("refreshAuth() error", e3);
            auth.signOut();
          }
        }
      };
    });
    exchanges = [cacheExchange, authEx, fetchExchange];
  }
  if (typeof window !== "undefined" && process.env.NEXT_PUBLIC_WEBSOCKET_URL) {
    const webSocketUrl = process.env.NEXT_PUBLIC_WEBSOCKET_URL;
    const wsClient = (0, import_graphql_ws.createClient)({
      url: webSocketUrl,
      connectionParams: () => {
        const token = config?.auth?.accessToken || null;
        return {
          type: "connection_init",
          action: "$connect",
          authToken: token
        };
      }
    });
    exchanges.push(
      subscriptionExchange({
        forwardSubscription(request) {
          const input = { ...request, query: request.query || "" };
          return {
            subscribe(sink) {
              const unsubscribe = wsClient.subscribe(input, sink);
              return { unsubscribe };
            }
          };
        }
      })
    );
  }
  const url = config?.url || "/graphql";
  const clientMemo = (0, import_react3.useCallback)(
    () => j({
      url,
      exchanges
    }),
    [config?.auth, url]
  );
  return clientMemo();
};

// src/graphqlClient.ts
var import_react4 = require("react");
var import_exchange_auth2 = require("@urql/exchange-auth");
var import_graphql_ws2 = require("graphql-ws");
var import_jwt_decode2 = __toESM(require("jwt-decode"));
var useGraphQLClient = (config) => {
  let exchanges = [cacheExchange, fetchExchange];
  if (config?.auth) {
    const { auth } = config;
    const authEx = (0, import_exchange_auth2.authExchange)(async (utils) => {
      return {
        addAuthToOperation(operation) {
          const token = auth.getAccessToken();
          if (token) {
            return utils.appendHeaders(operation, {
              Authorization: `Bearer ${token}`
            });
          }
          return operation;
        },
        willAuthError(_operation) {
          const token = auth.getAccessToken();
          if (!token) {
            return false;
          }
          try {
            const decodedToken = (0, import_jwt_decode2.default)(token);
            const currentTime = Math.floor(Date.now() / 1e3);
            const expiresIn = decodedToken.exp - currentTime;
            const expirationThreshold = 30;
            return expiresIn <= expirationThreshold;
          } catch (e3) {
            console.error("Error decoding JWT token:", e3);
            return true;
          }
        },
        didAuthError(error2, _operation) {
          return error2.graphQLErrors.some(
            (e3) => e3.extensions?.code === "FORBIDDEN" || e3.message?.toLowerCase().includes("expired token")
          );
        },
        async refreshAuth() {
          try {
            await auth.refreshAllTokens();
          } catch (e3) {
            if (auth.getAccessToken()) {
              console.log("refreshAuth() error", e3);
              await auth.signOut();
            }
          }
        }
      };
    });
    exchanges = [cacheExchange, authEx, fetchExchange];
  }
  if (typeof window !== "undefined" && process.env.NEXT_PUBLIC_WEBSOCKET_URL) {
    const webSocketUrl = process.env.NEXT_PUBLIC_WEBSOCKET_URL;
    const wsClient = (0, import_graphql_ws2.createClient)({
      url: webSocketUrl,
      retryAttempts: 10,
      connectionParams: () => {
        const token = config?.auth?.getAccessToken() || null;
        return {
          type: "connection_init",
          action: "$connect",
          authToken: token
        };
      }
    });
    exchanges.push(
      subscriptionExchange({
        forwardSubscription(request) {
          const input = { ...request, query: request.query || "" };
          return {
            subscribe(sink) {
              const unsubscribe = wsClient.subscribe(input, sink);
              return { unsubscribe };
            }
          };
        }
      })
    );
  }
  const url = config?.url || "/graphql";
  const clientMemo = (0, import_react4.useCallback)(
    () => j({
      url,
      exchanges
    }),
    [config?.auth, url]
  );
  return clientMemo();
};

// src/useInput.ts
var import_react7 = __toESM(require("react"));

// src/useDebounce.ts
var import_react6 = require("react");

// src/useTimeoutFn.ts
var import_react5 = require("react");
function useTimeoutFn(fn, ms = 0) {
  const ready = (0, import_react5.useRef)(false);
  const timeout = (0, import_react5.useRef)();
  const callback = (0, import_react5.useRef)(fn);
  const isReady = (0, import_react5.useCallback)(() => ready.current, []);
  const set = (0, import_react5.useCallback)(() => {
    ready.current = false;
    timeout.current && clearTimeout(timeout.current);
    timeout.current = setTimeout(() => {
      ready.current = true;
      callback.current();
    }, ms);
  }, [ms]);
  const clear = (0, import_react5.useCallback)(() => {
    ready.current = null;
    timeout.current && clearTimeout(timeout.current);
  }, []);
  (0, import_react5.useEffect)(() => {
    callback.current = fn;
  }, [fn]);
  (0, import_react5.useEffect)(() => {
    set();
    return clear;
  }, [ms]);
  return [isReady, clear, set];
}

// src/useDebounce.ts
function useDebounce(fn, ms = 0, deps = []) {
  const [isReady, cancel, reset] = useTimeoutFn(fn, ms);
  (0, import_react6.useEffect)(reset, deps);
  return [isReady, cancel];
}

// src/useInput.ts
var DEBOUNCE_TIME = 200;
var useInput = (props) => {
  const {
    initialValue,
    isRequired,
    validator,
    isDirtyFunc,
    formatter,
    dependsOn = []
  } = props;
  const [prevValue, setPrevValue] = (0, import_react7.useState)(initialValue);
  const [value2, setValue] = (0, import_react7.useState)(initialValue);
  const [debouncedValue, setDebouncedValue] = import_react7.default.useState(initialValue);
  const [isDirty, setIsDirty] = (0, import_react7.useState)(false);
  const [isValid, setIsValid] = (0, import_react7.useState)(true);
  useDebounce(
    () => {
      setDebouncedValue(value2);
    },
    DEBOUNCE_TIME,
    [value2]
  );
  const checkValidation = (val = value2) => {
    let isValid2 = true;
    if (isRequired) {
      isValid2 = isValid2 && !!val;
    }
    if (validator) {
      isValid2 = isValid2 && validator(val);
    }
    setIsValid(isValid2);
  };
  const onReset = (resetValue) => {
    setValue(resetValue || initialValue);
    setPrevValue(resetValue || initialValue);
    setIsDirty(false);
    setIsValid(true);
    checkValidation(resetValue || initialValue);
  };
  (0, import_react7.useEffect)(() => {
    checkValidation(value2);
    if (isDirtyFunc) {
      setIsDirty(isDirtyFunc(value2, prevValue));
    } else {
      setIsDirty(prevValue != value2);
    }
  }, [value2, isRequired, ...dependsOn]);
  const updateValue = (val) => {
    if (formatter) {
      val = formatter(val);
    }
    setValue(val);
  };
  const onChange = (event) => {
    const val = event.target?.value || event.target?.name || event;
    updateValue(val);
    if (event.target?.type === "radio") {
      console.log("EVENT", event.target.value, event.target.name);
      updateValue(event.target.name);
    } else if (event.target?.value) {
      updateValue(event.target.value);
    } else {
      updateValue(event);
    }
  };
  return {
    initialValue,
    value: value2,
    debouncedValue,
    setValue,
    isDirty,
    isValid,
    reset: onReset,
    checkValidation,
    bind: {
      selected: value2,
      value: value2,
      onChange
    }
  };
};

// src/useActivityDetection.tsx
var import_react8 = require("react");
var import_urql = require("urql");
var UpdateLastSeenMutation = `
  mutation {
    updateUserLastSeen
  }
`;
function useActivityDetection(interval = 6e4) {
  const activityFlag = (0, import_react8.useRef)(false);
  const timerId = (0, import_react8.useRef)(null);
  const [_, updateLastSeen] = (0, import_urql.useMutation)(UpdateLastSeenMutation);
  (0, import_react8.useEffect)(() => {
    updateLastSeen();
  }, []);
  (0, import_react8.useEffect)(() => {
    const activityHandler = () => {
      activityFlag.current = true;
    };
    window.addEventListener("mousemove", activityHandler);
    window.addEventListener("keydown", activityHandler);
    window.addEventListener("touchstart", activityHandler);
    const onHandleLastSeenTimer = async () => {
      if (activityFlag.current) {
        await updateLastSeen();
        activityFlag.current = false;
      }
    };
    timerId.current = setInterval(onHandleLastSeenTimer, interval);
    return () => {
      window.removeEventListener("mousemove", activityHandler);
      window.removeEventListener("keydown", activityHandler);
      window.removeEventListener("touchstart", activityHandler);
      if (timerId.current) {
        clearInterval(timerId.current);
      }
    };
  }, [interval]);
}

// src/useUpdate.ts
var import_react9 = require("react");
// Annotate the CommonJS export names for ESM import in node:
0 && (module.exports = {
  AuthContext,
  AuthProvider,
  configureAuth,
  currentSession,
  useActivityDetection,
  useAltGraphQLClient,
  useAuth,
  useBackgroundColor,
  useGraphQLClient,
  useInput,
  useViewer
});
