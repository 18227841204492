import { AuthConfig } from "@drumbeat/hooks";
import { UserType } from "@drumbeat/schema";

export const DEFAULT_AUTH_CONFIG: AuthConfig = {
  region: process.env.NEXT_PUBLIC_AWS_REGION,
  userPoolId: process.env.NEXT_PUBLIC_COGNITO_USER_POOL_ID,
  userPoolWebClientId: process.env.NEXT_PUBLIC_CLIENT_USER_POOL_WEB_CLIENT_ID,
  googleClientId: process.env.NEXT_PUBLIC_GOOGLE_OAUTH_CLIENT_ID,
  redirectSignIn: process.env.NEXT_PUBLIC_REDIRECT_SIGN_IN_ONBOARDING_APP,
  redirectSignOut: process.env.NEXT_PUBLIC_REDIRECT_SIGN_OUT_ONBOARDING_APP,
  cognitoDomain: process.env.NEXT_PUBLIC_COGNITO_OAUTH_DOMAIN,
  // FIXME: ssr: true makes request header too large
  ssr: false,
  localStorageProvider: {
    get: (_key) => null,
    set: (_key, _value) => {},
    remove: (_key) => {},
  },
};

export const USER_TYPE_COGNITO_CLIENT_ID_MAP = {
  [UserType.Therapist]:
    process.env.NEXT_PUBLIC_THERAPIST_USER_POOL_WEB_CLIENT_ID,
  [UserType.Client]: process.env.NEXT_PUBLIC_CLIENT_USER_POOL_WEB_CLIENT_ID,
  [UserType.Admin]: process.env.NEXT_PUBLIC_ADMIN_USER_POOL_WEB_CLIENT_ID,
};
